<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h1 class="System_admin_text_division" style="margin-left: 8px">
          HELP
        </h1>
      </v-col>

      <v-col cols="6" class="" style="float: right">
        <v-col cols="6" style="float: right">
          <v-text-field
            label="Search"
            autocomplete="off"
            class="search"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <div class="col-md-6">
        <span><b>Getting Started - Adding Customers</b></span>
        <p style="text-align: justify !important">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>

      <div class="col-md-6">
        <span><b>Getting Started - Adding Customers</b></span>
        <p style="text-align: justify !important">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
    </v-row>

    <v-row>
      <div class="col-md-6">
        <span><b>Getting Started - Adding Customers</b></span>
        <p style="text-align: justify !important">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>

      <div class="col-md-6">
        <span><b>Getting Started - Adding Customers</b></span>
        <p style="text-align: justify !important">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
      </div>
    </v-row>

    <v-row>
      <p class="text-center" style="margin-left: 48%"><b>FAQs</b></p>
    </v-row>

    <v-row>
      <p class="text-center" style="margin-left: 48%"><b>FAQs</b></p>
    </v-row>

    <template>
      <div>
        <v-expansion-panels class="mb-6">
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="color: #0157a0"
            >
              Question {{ i }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>

    <br />
    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: right">
          <router-link :to="'/SystemAdmin'"><span>Back</span></router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      props: ["title", "expandAll"],
      expanded: false,

      bids_headers_modify: [
        { text: "Division", value: "Division" },
        { text: "Foreman", value: "Foreman" },
        { text: "Customer", value: "Customer" },
        { text: "Location", value: "Location" },
        { text: "Start Date", value: "StartDate" },
      ],

      bids_items_modify: [
        {
          Division: "FRWP",
          Foreman: "Carlos",
          Customer: "Jane Smith",
          Location: "14,580",
          StartDate: "6/22/20",
        },
        {
          Division: "FRWP",
          Foreman: "Carlos",
          Customer: "Jane Smith",
          Location: "14,580",
          StartDate: "6/22/20",
        },
        {
          Division: "FRWP",
          Foreman: "Carlos",
          Customer: "Jane Smith",
          Location: "14,580",
          StartDate: "6/22/20",
        },
      ],

      items: ["ALL", "Demo", "React JS", "Vuejs", "Vuetify", "Type script"],
    };
  },
  methods: {
    customerDetail(value) {
      console.log(value.FirstName); // Get customer firstname
      this.$router.push("/ProjectDetail");
    },

    toggleCardState() {
      this.expanded = !this.expanded;
    },
    closeAll() {
      this.expanded = false;
    },
  },
};
</script>
<style type="text/css">
.sc-title .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sc-title h1 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}
.customer-search-wrap .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.customer-search-table .v-data-table {
  border-radius: 0;
  box-shadow: none !important;
}
.customer-search-table .v-data-table table {
  border: none !important;
}
.customer-search-table .v-data-table table th {
  border: none !important;
  color: #2d2d2d !important;
  font-family: Poppins;
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px !important;
  height: 50px !important;
  padding: 0px 30px !important;
}
.customer-search-table .v-data-table table td {
  border: none !important;
}
thead.v-data-table-header tr th:first-child {
  border-radius: 5px 0 0 5px;
}
thead.v-data-table-header tr th {
  background-color: #f3f3f3;
}
thead.v-data-table-header tr th:last-child {
  border-radius: 0 5px 5px 0;
}
.customer-search-table .v-data-table table tbody tr:last-child td {
  border-bottom: 1px solid #c4c4c4 !important;
}
.customer-search-table .v-data-table table td {
  padding: 0 30px !important;
  height: 50px !important;
  border-top: 1px solid #c4c4c4 !important;
  white-space: nowrap;
}
.customer-search-table .v-data-table table td:first-child {
  border-left: 1px solid #c4c4c4 !important;
  border-radius: 5px 0 0 5px;
}
.customer-search-table .v-data-table table td:last-child {
  border-right: 1px solid #c4c4c4 !important;
  border-radius: 0 5px 5px 0;
}
.customer-search-table .v-data-footer .v-data-footer__select,
.customer-search-table .v-data-footer .v-data-footer__pagination {
  display: none;
}
.customer-search-table .v-data-footer {
  margin-top: 30px;
  border: none !important;
}
.customer-search-table .v-data-footer button {
  background: #dee9f3;
}
.customer-search-table .v-data-footer button * {
  color: #0089f3 !important;
}
.customer-search-table th .v-icon:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #000;
  font-size: 16px;
  position: relative;
  top: -5px;
  left: 10px;
}
.customer-search-table th .v-icon {
  transform: none !important;
  opacity: 1 !important;
}
.customer-search-table th .v-icon:after {
  content: "\f0d7";
  font-family: FontAwesome;
  opacity: 1 !important;
  background: transparent !important;
  transform: none !important;
  color: #000;
  font-style: normal;
  position: relative;
  top: 3px;
  font-size: 16px;
  left: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 13px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  height: 45px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
@media only screen and (max-width: 599px) {
  .v-application .sc-title {
    margin-top: 20px !important;
  }
  .sc-title h1 {
    font-size: 22px;
  }
  .customer-search-table .v-data-footer {
    margin-top: 0;
  }
  .v-application .customer-search-wrap {
    padding-top: 0 !important;
  }
  .sc-title h1 {
    font-size: 22px;
  }
  .customer-search-table .v-data-footer {
    margin-top: 0;
  }
}
@media only screen and (max-width: 587px) {
  .customer-search-table .v-data-table table td:first-child {
    border-radius: 0;
    border-left: 0 !important;
  }
  .customer-search-table .v-data-table table td:last-child {
    border-radius: 0;
    border-right: 0 !important;
  }
  .customer-search-table .v-data-table__mobile-table-row {
    margin-bottom: 20px;
    display: block !important;
    border: 1px solid #c4c4c4;
  }
}
</style>
